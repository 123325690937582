<template>
  <v-container id="search" fluid tag="section">
    <v-card color="transparent">
      <v-col class="text-right">
        <v-btn
          icon
          rounded
          color="#1b5e20"
          outlined
          elevation="5"
          large
          @click.native="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
      <v-text-field
        hide-details
        flat
        autofocus
        label="Search"
        prepend-inner-icon="mdi-magnify"
        v-model="keyword"
        @input="doSearch"
        icon
        rounded
        color="#1b5e20"
        outlined
        large
      >
      </v-text-field>

      <v-card-text>
        <v-subheader v-if="keyword.length > 0">
          Result search "{{ keyword }}"
        </v-subheader>

        <v-alert :value="products.length == 0 && keyword.length > 0" color="warning">
          Sorry, but no results were found.
        </v-alert>

        <!-- Hasil pencarian ditampilkan di sini -->
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex v-for="product in products" :key="`product-` + product.id" xs2>
              <product-item :product="product" />
            </v-flex>
          </v-layout>
        </v-container>
        <template>
          <div class="text-center">
            <v-pagination
              v-model="page"
              @input="go"
              :length="lengthPage"
              :total-visible="5"
              color="#1b5e20"
            >
            </v-pagination>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "search",
  components: {
    ProductItem: () =>
      import("@/components/ProductItem.vue"),
  },
  data() {
    return {
      keyword: "",
      products: [],
      page: 0,
      lengthPage: 0,
    };
  },
  created() {
    this.go();
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
    }),
    go() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/products-pos?page=" + this.page;
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          let { meta } = response.data;
          this.products = data;
          // jumlah halaman di dapat dari meta endpoint products
          this.lengthPage = meta.last_page;
          this.page = meta.current_page;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    doSearch() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let keyword = this.keyword;
      if (keyword.length > 0) {
        this.axios
          .get("/products/search/" + keyword, config)
          .then((response) => {
            let { data } = response.data;
            this.products = data;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.products = [];
      }
    },
    close() {
      // this.$emit('closed', false)
      this.$router.push("/pages/point-of-sale");
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
};
</script>
